import classNames from "classnames";

import Image from "components/imageNew/image";
import { ChevronIcon } from "components/icons/icons";
import { EventCardProps } from "./types";
import { SponsorLogoAndText } from "./talkElements";
import { useMixpanelTracking } from "lib/hooks/useMixpanelTracking";
import { formatDateEventCard, FormatDateEventCardOptions } from "./utils";

type DateStringProps = {
  eventDateTime: Date;
  className?: string;
  options: FormatDateEventCardOptions;
};

const DateString = ({
  eventDateTime,
  className = "",
  options = {},
}: DateStringProps) => {
  return (
    <p
      data-testid="event-time"
      className={classNames(className, "font-bold text-[16px] text-mono-40")}
    >
      {formatDateEventCard(eventDateTime, options)}
    </p>
  );
};

export const EventCard = ({
  color = "cerise",
  chevronClasses = "",
  heading,
  title,
  image,
  sponsorImage,
  body,
  registration,
  eventDateTime,
  cardLink,
  variant,
  calendar,
  cardContainerClasses,
  timezone,
  duration,
  withDuration,
  dateComponentOverride,
  excerpt,
  sponsorDetails,
}: EventCardProps) => {
  const { track } = useMixpanelTracking();

  return (
    <div
      data-testid="event-card"
      className={classNames(`relative bg-white`, {
        "mx-auto max-md:max-w-[320px]": variant === "subscriberEvent",
      })}
      data-variant={variant}
    >
      <a
        href={cardLink}
        className={classNames("peer absolute inset-0 -z-0 outline-none")}
        onClick={async () =>
          await track("Event card click", {
            "Click link": cardLink,
          })
        }
      >
        <span
          className={"sr-only"}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </a>
      <div
        data-testid="event-card__container"
        className={classNames(
          "flex justify-between gap-x-6 p-4",
          "[&_.overlay]:peer-active:z-[2] [&_.overlay]:peer-active:bg-[rgba(2,2,49,0.2)]",
          "peer-focus:outline-none peer-focus-visible:outline-none peer-focus-visible:ring peer-focus-visible:ring-2 peer-focus-visible:ring-inset peer-focus-visible:ring-black-rock",
          "[&_.chevron]:peer-hover:right-0 [&_.chevron]:peer-focus-visible:right-0 [&_.chevron]:peer-active:right-0",
          {
            "lg:min-h-[324px]": variant === "talk",
          },
          cardContainerClasses
        )}
      >
        <div
          className={
            "overlay pointer-events-none absolute bottom-0 left-0 right-0 top-0 transition-colors"
          }
        ></div>
        {image || sponsorImage ? (
          <div
            data-testid={"event-card__image-container"}
            className={classNames(`flex flex-col`, {
              "hidden lg:block": variant === "talk" || variant === "breakfast",
            })}
          >
            <div className="pointer-events-none">
              {image && (
                <div
                  className={classNames("w-[210px]", {
                    "mx-auto w-[288px] max-w-full md:mx-[unset] md:w-[210px]":
                      variant === "subscriberEvent",
                  })}
                >
                  <Image
                    className={classNames(`h-[210px] w-full object-cover`, {
                      "h-auto md:h-[210px]": variant === "subscriberEvent",
                    })}
                    src={image.url}
                    width={image.width}
                    height={image.height}
                    sizes="min(400px, 100vw)"
                    alt={image.alt || `${title} event image`}
                    quality={75}
                    placeholder="blur"
                    blurDataURL={image.blurUpThumb || undefined}
                  />
                </div>
              )}
              {sponsorImage ? (
                <SponsorLogoAndText
                  image={sponsorImage}
                  sponsorDetails={sponsorDetails}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="flex h-auto flex-auto flex-col justify-between">
          <div className="">
            <div className="mb-2 flex items-center">
              <p
                className={classNames("font-bold text-[16px]", {
                  "text-cerise": color === "cerise",
                  "text-pro-blue": color === "pro-blue",
                  "hidden lg:block": variant === "subscriberEvent",
                })}
              >
                {heading}
              </p>

              <div className="hidden items-center md:flex">
                <div
                  className={classNames("mx-2 text-[#999999]", {
                    "hidden lg:block": variant === "subscriberEvent",
                  })}
                >
                  •
                </div>
                {dateComponentOverride || (
                  <DateString
                    eventDateTime={eventDateTime}
                    options={{ timezone, duration, withDuration }}
                  />
                )}
                <span className="z-[1]">{calendar}</span>
              </div>
            </div>
            <h3 className="mb-2 font-bold text-[22px] leading-[130%]">
              {title}
            </h3>
            {excerpt && (
              <p
                className={
                  "mb-5 text-[16px] leading-[130%] text-mono-40 md:mb-3 lg:mb-5"
                }
              >
                {excerpt}
              </p>
            )}
            <div className="mt-3 flex items-center md:hidden">
              {dateComponentOverride || (
                <DateString
                  eventDateTime={eventDateTime}
                  options={{ timezone, duration, withDuration }}
                />
              )}{" "}
              {calendar && <span className="z-[1]">{calendar}</span>}
            </div>
            {body}
          </div>

          <div className="flex w-full justify-end self-end">{registration}</div>
        </div>

        <div
          data-testid="event-card__chevron"
          className={classNames(
            "pointer-events-none relative flex h-auto w-[34px] items-center text-[28px]",
            chevronClasses
          )}
        >
          <span
            className={
              "chevron absolute right-[10px] text-black-rock transition-[right]"
            }
          >
            <ChevronIcon width={24} className={"-rotate-90"} />
          </span>
        </div>
      </div>
    </div>
  );
};
