import classNames from "classnames";

import { Hero } from "./components/hero/hero";
import { TalksSection } from "./components/talks/talksSection";
import { Newsletters } from "./components/newsletters/newsletterSection";
import { PodcastsSection } from "./components/podcasts/podcastsSection";
import Featured from "./components/featured/featured";
import { ReportsAndRankings } from "./components/insights/reportsAndRankings";
import { AnalysisAndBriefings } from "./components/insights/analysisAndBriefings";
import { SectionHeading } from "./components/sectionHeading";
import { Events } from "./components/events";
import { useHomepageDatoProps } from "lib/store/homepage";

const innerContainerClasses = classNames(
  "mx-auto w-100 max-w-[1140px] px-5 pb-[70px] pt-[30px] md:pt-[40px] lg:pt-[40px] md:mx-auto lg:max-w-[1220px] lg:px-0"
);

export const HomepageView = () => {
  const data = useHomepageDatoProps();

  return (
    <main className="w-100 overflow-x-hidden bg-[#F4F4F6] pt-[65px] text-black-rock">
      <div className={innerContainerClasses}>
        <Hero />
        <Featured />
        <Newsletters />
        <SectionHeading text={data.insightsSectionHeading} />
        <AnalysisAndBriefings />
        <ReportsAndRankings />
        <SectionHeading text={data.communitySectionHeading} />
        <Events />
        <TalksSection />
        <PodcastsSection />
      </div>
    </main>
  );
};
