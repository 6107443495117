export const Hero = () => {
  return (
    <div className="flex select-none">
      <div className="w-full">
        <h1 className="flex flex-col text-left font-recklessBold text-[30px] sm:text-[52px] lg:flex-row lg:text-[52px]">
          <span className="lg:mr-3">On startup Europe,</span>
          <span>for startup Europe</span>
        </h1>
      </div>
    </div>
  );
};
